import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEmailTemplateButton } from './EmailTemplateButtonProvider';

export const EmailTemplateMenuItemButton = (props: { disabled?: boolean }) => {
  const { t } = useTranslation();
  const { emailTempaltePickerFieldRef, onClick } = useEmailTemplateButton();
  return (
    <MenuItem
      key={'email'}
      ref={emailTempaltePickerFieldRef}
      onClick={onClick}
      {...props}
    >
      {t('TICKET.SEND_EMAIL')}
    </MenuItem>
  );
};
