import styles from './ReportPreviewMask.module.scss';

import { gql, useQuery } from '@apollo/client';
import {
  Download,
  Fullscreen,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import printJS from 'print-js';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BanderoleInfo } from '@work4all/components/lib/components/banderole/BanderoleInfo';
import { ReportType } from '@work4all/components/lib/components/entity-picker/report-type-picker/ReportTypePicker';
import { TextmarkContext } from '@work4all/components/lib/components/entity-picker/textmark-picker';
import { useErpFileName } from '@work4all/components/lib/components/entity-preview/erp-preview/hooks/use-erp-file-name';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  FilePreview,
  FilePreviewProvider,
  useFilePreview,
} from '@work4all/components/lib/preview/FilePreviewProvider';

import { useUser } from '@work4all/data';
import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';
import { useSetArchivePdf } from '@work4all/data/lib/hooks/use-set-archive-pdf';
import { useUploadTempFile } from '@work4all/data/lib/hooks/use-upload-temp-file';
import { useSetting } from '@work4all/data/lib/settings';
import { downloadAuthed, getObjectUrlAuthed } from '@work4all/data/lib/utils';

import { IAttachmentEntity } from '@work4all/models';
import { CreateCrystalReportRequest } from '@work4all/models/lib/Classes/CreateCrystalReportRequest.entity';
import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { Report } from '@work4all/models/lib/Classes/Report.entity';
import { TempFile } from '@work4all/models/lib/Classes/TempFile.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { ConvertInvoiceToRaStatuses } from '@work4all/models/lib/Enums/ConvertInvoiceToRaStatuses.enum';
import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ReportBzObjType } from '@work4all/models/lib/Enums/ReportBzObjType.enum';

import { ReportPickerField } from '../../../../../../../../components/entity-picker/ReportPickerField';
import { ReportTypePickerField } from '../../../../../../../../components/entity-picker/ReportTypePickerField';
import { NavigationOverlayHeader } from '../../../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { useLanguageManager } from '../../../../../../../../providers/LanguageManagerProvider';
import { settings } from '../../../../../../../../settings';
import {
  ApiErrors,
  ValidationErrors,
} from '../../../../../../../apollo/ValidationErrors';
import { EmailTemplateButtonProvider } from '../../../../../../components/email-template-button/EmailTemplateButtonProvider';
import { EmailTemplateSplitButton } from '../../../../../../components/email-template-button/EmailTemplateSplitButton';
import { SignatureConf } from '../../simple-pdf-report/components/signature-conf/SignatureConf';
import { SimplePDFReport } from '../../simple-pdf-report/SimplePDFReport';
import { SimpleReportConf } from '../../simple-pdf-report/SimpleReportConf';
import { bzObjToEntityType } from '../../simple-pdf-report/utils';

import {
  ConvertInvoiceToRaAbilitySwitchButton,
  ConvertInvoiceToRaProvider,
  useConvertInvoiceToRaContext,
} from './convert-invoice-to-ra';
import { CrystalReportOptionsConf } from './crystal-report-options-conf/CrystalReportOptionsConf';
import { useAttachZugferdToTempfile } from './use-attach-zugferd-to-tempfile';
import { useInvoiceToXInvoice } from './use-invoice-to-x-invoice';
import { useReportValues } from './use-report-values';

interface ReportPreviewMaskProps extends BaseReportPreviewMaskProps {
  id: number | string;
}

interface CrystalReportResponse {
  createCrystalReport: CreateCrystalReport;
}

interface CreateCrystalReport {
  displayName: string;
  tempFile: TempFile;
  tempFileID: string;
  typename: string;
}

const CRYSTAL_REPORT_QUERY = gql`
  query CreateCrystalReport($input: CreateCrystalReportRequest) {
    createCrystalReport(input: $input) {
      displayName
      tempFile {
        dateiname
        fileInfos {
          downloadMimeType
          downloadUrl
          fileEntityFilename
          previewUrl
          previewMimeType
          fileSize
          fileServiceProviderInfos {
            customUrl
            exists
            fspUrl
            thumbnail
            size
            mimeType
            key
            id
            filename
          }
          fileRequestType
        }
        id
        datum
      }
      tempFileId
    }
  }
`;

export const ReportPreviewMask = (props: ReportPreviewMaskProps) => {
  const { id, reportType: incomingReportType, bzObjectType } = props;
  const [report, setReport] = useState<Report | null>(props.report);

  const isOnPremise = useSetting(settings.isOnPremiseEnvironment());

  const { values: reportOptionValues, onChange: onChangeReportOptionValues } =
    useReportValues({
      reportId: report?.id,
      bzObjectType,
    });
  const [reportType, setReportType] = useState<ReportType>(ReportType.Simple);

  useEffect(() => {
    setReportType(incomingReportType);
  }, [incomingReportType]);

  const { loading, data, error } = useQuery<
    CrystalReportResponse,
    { input: CreateCrystalReportRequest }
  >(CRYSTAL_REPORT_QUERY, {
    skip:
      !report || reportType !== ReportType.CrystalReports || isOnPremise.value,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        bzObjType:
          bzObjectType === BzObjType.ANGEBOT
            ? ReportBzObjType.ANGEBOT
            : bzObjectType === BzObjType.AUFTRAG
            ? ReportBzObjType.AUFTRAG
            : report?.bzObjType,
        reportCode: report?.id,
        objectCode: parseInt(id.toString()),
        options: Object.keys(reportOptionValues).map((key) => ({
          optionId: parseInt(key),
          value: reportOptionValues[key],
        })),
      },
    },
  });
  const parsedErrors = error
    ? ValidationErrors.parseErrors(error?.graphQLErrors)
    : undefined;

  return (
    <FilePreviewProvider file={data?.createCrystalReport.tempFile}>
      <ConvertInvoiceToRaProvider invoiceId={Number(id)} reportId={report?.id}>
        <ReportPreviewMaskInternal
          {...props}
          isOnPremise={isOnPremise.value}
          bzObjectId={id}
          errors={parsedErrors}
          report={report}
          loading={loading}
          setReport={setReport}
          reportType={reportType}
          onReportTypeChange={setReportType}
          reportOptionValues={reportOptionValues}
          onChangeReportOptionValues={onChangeReportOptionValues}
        />
      </ConvertInvoiceToRaProvider>
    </FilePreviewProvider>
  );
};

interface ReportPreviewMaskInternalProps extends BaseReportPreviewMaskProps {
  setReport: (report: Report) => void;
  errors?: ApiErrors;
  loading: boolean;
  onReportTypeChange: (value: ReportType) => void;
  reportType: ReportType;
  bzObjectId?: string | number;
  isOnPremise: boolean;
}

interface BaseReportPreviewMaskProps {
  contactId?: number;
  businessPartnerId?: number;
  businessPartnerType?: Entities.customer | Entities.supplier;
  businessPartnerLanguageId?: number;
  report: Report | null;
  reportType: ReportType;
  data?: ERPTypes;
  reportBzObjectType?: ReportBzObjType;
  bzObjectType?: BzObjType;
  entity: Entities;
  reportOptionValues?: Record<number, boolean>;
  onChangeReportOptionValues?: (optId: number, value: boolean) => void;
}

const ReportPreviewMaskInternal = (props: ReportPreviewMaskInternalProps) => {
  const {
    bzObjectType,
    reportBzObjectType,
    report,
    setReport,
    contactId,
    businessPartnerId,
    businessPartnerType,
    businessPartnerLanguageId,
    errors,
    loading,
    data,
    bzObjectId,
    reportType,
    onReportTypeChange,
    reportOptionValues,
    isOnPremise,
    onChangeReportOptionValues,
  } = props;
  const { t } = useTranslation();
  const { file, openFullscreen } = useFilePreview();
  const [simpleReportBlob, setSimpleReportBlob] = useState<Blob>(null);
  const httpHeaders = useAuthHeaders();

  const uploadTempFile = useUploadTempFile();
  const setArchivePdf = useSetArchivePdf();

  const [showSimpleReportConf, setShowSimpleReportConf] = useState(false);

  const isSmDown = useMediaQuery<Theme>((t) => t.breakpoints.down('sm'));

  const entityType = bzObjToEntityType[bzObjectType];

  const { resources: languageResources } = useLanguageManager();

  const initialFileName = useErpFileName({
    data,
    entityType,
    languageResources,
  });

  const [fileName, setFileName] = useState(initialFileName);

  useEffect(() => {
    setFileName(initialFileName);
  }, [initialFileName]);

  const { convertInvoice, eInvoiceType } = useInvoiceToXInvoice({
    data,
    entityType,
    id: bzObjectId,
  });

  const { attachZugferdToTempFile } = useAttachZugferdToTempfile();

  const getEmailParams = useCallback(async () => {
    const tempFileAttachements = [];
    let attachment: IAttachmentEntity = {
      date: new Date().toISOString(),
      fileInfos: file?.fileInfos,
      fileName,
      id: file?.id,
    };
    if (reportType === ReportType.Simple) {
      try {
        const uploadFile = new File([simpleReportBlob], fileName);
        const {
          generatedObject,
          downloadUrl,
          previewMimeType,
          downloadUrlForPreview,
          downloadMimeType,
        } = await uploadTempFile(uploadFile);

        setArchivePdf({
          subObjectTargetType: bzObjectType,
          target: 'ArchivPdf',
          targetCode: parseInt(bzObjectId.toString()),
          tempfileId: generatedObject,
        });

        if (eInvoiceType === EInvoiceFormat.ZUG_FE_RD) {
          const file = await attachZugferdToTempFile({
            variables: {
              invoiceCode:
                typeof bzObjectId === 'string'
                  ? parseInt(bzObjectId)
                  : bzObjectId,
              tempPdfFile: generatedObject,
            },
          });
          tempFileAttachements.push(file.data.attachZugferdToTempfile);
        } else {
          attachment = {
            date: new Date().toISOString(),
            fileInfos: {
              downloadMimeType,
              downloadUrl,
              previewMimeType,
              previewUrl: downloadUrlForPreview,
              fileEntityFilename: fileName,
              fileSize: uploadFile.size,
            },
            fileName,
            id: generatedObject,
          };
          tempFileAttachements.push(attachment);
        }
      } catch (err) {
        console.warn(err);
      }
    } else {
      tempFileAttachements.push(attachment);
    }

    if (eInvoiceType === EInvoiceFormat.X_RECHNUNG_CII) {
      const result = await convertInvoice();
      tempFileAttachements.push(result.data.invoiceToXInvoice);
    }

    const entityTemplate = businessPartnerId
      ? {
          entity: contactId ? Entities.contact : businessPartnerType,
          id: contactId
            ? `${contactId}:${businessPartnerType}:${businessPartnerId}`
            : businessPartnerId,
        }
      : undefined;

    return {
      entityTemplate,
      params: {
        tempFileAttachements: JSON.stringify(tempFileAttachements),
        processedMailTemplateArgs: JSON.stringify({
          project: data.project,
          projectId: data.projectId,
          textFieldCustom: [
            {
              key: 'ERP' satisfies TextmarkContext,
              value: JSON.stringify({
                kind: t(`COMMON.${props.entity?.toUpperCase()}`),
                note: data?.note?.split('\n')[0],
                number:
                  props.entity === Entities.contract
                    ? data?.contractNumber
                    : data?.number,
              }),
            },
          ],
        }),
      },
    };
  }, [
    file?.fileInfos,
    file?.id,
    fileName,
    reportType,
    eInvoiceType,
    businessPartnerId,
    contactId,
    businessPartnerType,
    t,
    props.entity,
    data?.note,
    data?.contractNumber,
    data?.number,
    simpleReportBlob,
    uploadTempFile,
    setArchivePdf,
    bzObjectType,
    bzObjectId,
    attachZugferdToTempFile,
    convertInvoice,
  ]);

  const download = useCallback(
    async (e) => {
      e.preventDefault();

      let downloadUrl = file?.fileInfos?.downloadUrl;

      if (reportType === ReportType.Simple) {
        try {
          const uploadFile = new File([simpleReportBlob], fileName);
          const { downloadUrl: tmpDownloadUrl } = await uploadTempFile(
            uploadFile
          );
          downloadUrl = tmpDownloadUrl;
        } catch (err) {
          console.warn(err);
        }
      }

      downloadAuthed(downloadUrl, fileName, httpHeaders);
    },
    [
      file?.fileInfos?.downloadUrl,
      fileName,
      httpHeaders,
      reportType,
      simpleReportBlob,
      uploadTempFile,
    ]
  );

  const { close } = useHistoryStack();
  const { isEnabled, convert, alert, reportId } =
    useConvertInvoiceToRaContext();

  const convertInvoiceToRaHandler = useCallback(() => {
    if (reportType === ReportType.Simple || !isEnabled || !reportId) return;

    convert().then((response) => {
      alert(response.invoiceToOutgoingInvoiceLedger);

      if (
        response.invoiceToOutgoingInvoiceLedger ===
        ConvertInvoiceToRaStatuses.SUCCESS
      ) {
        close();
      }
    });
  }, [alert, close, convert, isEnabled, reportId, reportType]);

  const print = useCallback(
    async (e) => {
      e.preventDefault();

      convertInvoiceToRaHandler();

      let downloadUrl = file?.fileInfos?.downloadUrl;

      if (reportType === ReportType.Simple) {
        try {
          const uploadFile = new File([simpleReportBlob], fileName);
          const { downloadUrl: tmpDownloadUrl } = await uploadTempFile(
            uploadFile
          );
          downloadUrl = tmpDownloadUrl;
        } catch (err) {
          console.warn(err);
        }
      }

      const object = await getObjectUrlAuthed(downloadUrl, httpHeaders);
      printJS(object);
    },
    [
      convertInvoiceToRaHandler,
      file?.fileInfos?.downloadUrl,
      fileName,
      httpHeaders,
      reportType,
      simpleReportBlob,
      uploadTempFile,
    ]
  );

  const renderBreadcrumbsChildren = () => (
    <div className={styles['header-wrapper']}>
      <IconButton color="primary" onClick={print}>
        <PrintIcon />
      </IconButton>
      <IconButton color="primary" onClick={download}>
        <Download />
      </IconButton>

      <EmailTemplateSplitButton />

      {isDesktop && reportType === ReportType.CrystalReports && (
        <IconButtonWithTooltip
          tooltip={t('COMMON.FULLSCREEN')}
          icon={<Fullscreen />}
          onClick={openFullscreen}
        />
      )}
    </div>
  );
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const user = useUser();

  const errorComponent = errors?.length
    ? errors.map((x) => (
        <Typography key={x.code} component="span" variant="body2" color="error">
          {ValidationErrors.translateError(x.code)}
        </Typography>
      ))
    : null;

  useEntityEvents((event) => {
    if (
      event.entity === Entities.eMail &&
      event.type === 'upsert' &&
      event.data
    ) {
      convertInvoiceToRaHandler();
    }
  });

  const overrideRecipients =
    bzObjectType === BzObjType.RECHNUNG
      ? data.businessPartnerContactCombined?.businessPartner?.data
          ?.invoiceEMailAddress
      : undefined;

  return (
    <EmailTemplateButtonProvider
      kind={[EMailTemplateKind.KEINE, EMailTemplateKind.ERP_OBJECTS]}
      mainKind={EMailTemplateKind.ERP_OBJECTS}
      isSplit={true}
      getEmailParams={getEmailParams}
      noTemplate
      languageId={businessPartnerLanguageId}
      overrideRecipients={overrideRecipients}
    >
      <div className={styles['wrapper']}>
        <NavigationOverlayHeader
          title={t('COMMON.PREVIEW')}
          breadcrumbsChildren={
            file || simpleReportBlob ? renderBreadcrumbsChildren() : undefined
          }
          forceStackItems
        />
        {(data.releaseInformation?.releaseNeeded ||
          (isOnPremise && reportType === ReportType.CrystalReports)) && (
          <BanderoleInfo
            visible
            text={
              isOnPremise && reportType === ReportType.CrystalReports ? (
                t('ALERTS.ON_PREMISE_REPORT')
              ) : (
                <Box display="flex" gap="0.5rem" padding="1rem 0 ">
                  {t('ALERTS.RELEASE_PROCESS', {
                    entity: t(`COMMON.${props.entity.toUpperCase()}_plural`),
                  })}
                  <Tooltip title={t('ALERTS.RELEASE_PROCESS_TOOLTIP')}>
                    <Typography variant="body2" color="var(--brand01)">
                      {t('ALERTS.RELEASE_PROCESS_MORE')}
                    </Typography>
                  </Tooltip>
                </Box>
              )
            }
          />
        )}

        <div className={styles['content-wrapper']}>
          <div className={styles['left-panel']}>
            <Stack gap="1rem" width="20rem">
              <ReportTypePickerField
                clearable={false}
                value={reportType}
                onChange={onReportTypeChange}
              />
              {reportType === ReportType.CrystalReports && (
                <ReportPickerField
                  reportBzObjectType={reportBzObjectType}
                  value={report}
                  error={errors?.length ? ' ' : undefined}
                  onChange={(report) => {
                    setReport(report);
                  }}
                />
              )}

              <LabeledInput
                value={fileName.split('.pdf')[0]}
                endAdornment={
                  <Box pt="1rem">
                    <Typography color="var(--text03)">.pdf</Typography>
                  </Box>
                }
                inputProps={{
                  style: {
                    // textAlign: 'right',
                  },
                }}
                label={t('COMMON.FILE')}
                onChange={(e) => {
                  setFileName(e.target.value + '.pdf');
                }}
              />

              {entityType === Entities.invoice &&
                reportType !== ReportType.Simple &&
                reportId && <ConvertInvoiceToRaAbilitySwitchButton />}

              {reportType === ReportType.CrystalReports && (
                <CrystalReportOptionsConf
                  options={report?.reportOptions}
                  bzObjType={bzObjectType}
                  reportId={report?.id}
                  reportOptionValues={reportOptionValues}
                  onChangeReportOptionValues={onChangeReportOptionValues}
                />
              )}

              {reportType === ReportType.Simple && user.isMaster && (
                <>
                  {bzObjectType === BzObjType.LIEFERSCHEIN &&
                    data?.signature && <SignatureConf bzObj={data} />}
                  <Box>
                    <BaseActionButton
                      icon={
                        showSimpleReportConf ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )
                      }
                      onClick={() =>
                        setShowSimpleReportConf(!showSimpleReportConf)
                      }
                    >
                      {t(
                        showSimpleReportConf
                          ? 'SIMPLE_REPORT.HIDE_CONF'
                          : 'SIMPLE_REPORT.SHOW_CONF'
                      )}
                    </BaseActionButton>
                  </Box>

                  {showSimpleReportConf ? (
                    <SimpleReportConf bzObjType={bzObjectType} />
                  ) : null}
                </>
              )}
            </Stack>
            {isDesktop && errorComponent}
          </div>
          <div className={styles['right-content']}>
            {loading && reportType === ReportType.CrystalReports && (
              <LinearProgress />
            )}
            {!isDesktop && errors?.length && (
              <div className={styles['mobile-error']}>{errorComponent}</div>
            )}
            {file && reportType === ReportType.CrystalReports && (
              <FilePreview
                file={file}
                initialScale={isSmDown ? undefined : 0.6}
                initalTranslate={isSmDown ? undefined : 'translate(30%, 50px)'}
              />
            )}
            {reportType === ReportType.Simple && data && (
              <SimplePDFReport
                bzObjType={bzObjectType}
                data={data}
                onPdfBlobChange={setSimpleReportBlob}
              />
            )}
          </div>
        </div>
      </div>
    </EmailTemplateButtonProvider>
  );
};
