import { useEventCallback } from '@mui/material/utils';
import { difference } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IResponse, IUser, useDataProvider, useUser } from '@work4all/data';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { MailboxConfiguration } from '@work4all/models/lib/Classes/MailboxConfiguration.entity';
import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ContactType } from '@work4all/models/lib/Enums/ContactType.enum';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { settings, useSetting } from '../../../../../settings';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskExtendedConfig } from '../../types';
import { AssignableEntityResult } from '../../utils/use-assignable-template-entity';

import { emailRequestData, emailRequestVars } from './email-request-data';
import { EMAILMODES } from './EmailActions';
import { EmailMaskFormValue } from './types';
import { useEmailContactData } from './use-email-contact-data';
import { calculateToMailContacts } from './utils/calculateToMailContacts';

const NO_TEMPLATE = {
  enabled: false,
  entity: null,
  data: null,
  pending: false,
};

export function useEmailMaskInit({
  mask,
  template,
  activeTicketContext,
  previousEmail,
  additionalTemplateVariables,
}: {
  mask: MaskExtendedConfig;
  template: AssignableEntityResult;
  activeTicketContext: Ticket | null;
  previousEmail: IResponse<EMail>;
  additionalTemplateVariables?: Record<string, unknown>;
}) {
  const { t } = useTranslation();

  const { params } = mask;
  const { senderAddress, receivers, mode: actionMode } = params;

  const user = useUser();

  const [isLoading, setLoading] = useState(true);
  const [draftValues, setDraftValues] = useState<EMail>(null);
  const [values, setValues] = useState<EmailMaskFormValue>(() => {
    return createEmail({ user, template: NO_TEMPLATE, receivers });
  });

  const mailboxesRequest = useMemo<DataRequest>(() => {
    const data: MailboxConfiguration = {
      id: null,
      smtpAddress: null,
    };

    return {
      operationName: 'GetMailboxes',
      entity: Entities.mailboxConfiguration,
      data,
      completeDataResponse: true,
    };
  }, []);

  const mailboxesData = useDataProvider<MailboxConfiguration>(mailboxesRequest);

  const currentEmailRequest = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: mask.id } }];

    const request: DataRequest = {
      operationName: 'GetCurrentEmail',
      entity: Entities.eMail,
      data: emailRequestData,
      filter,
      vars: emailRequestVars,
    };
    return request;
  }, [mask.id]);

  const currentEmailData = useDataProvider<EMail>(
    currentEmailRequest,
    !mask.isEditMode
  );

  const {
    customerApMailData,
    supplierApMailData,
    customerMailData,
    supplierMailData,
  } = useEmailContactData({ to: draftValues?.to, cc: draftValues?.cc });

  const { value: lastUsedMailboxId } = useSetting(settings.lastUsedMailboxId());

  const makeInitialValues = useEventCallback(() => {
    if (!isLoading) return;

    if (draftValues != null) {
      // Wait for all dependencies to be resolved
      if (
        customerApMailData.pending ||
        supplierApMailData.pending ||
        customerMailData.pending ||
        supplierMailData.pending ||
        mailboxesData.pending
      ) {
        return;
      }

      const normalized = normalizeFormValue(draftValues);
      const finalValues = assignContactsToValues(normalized);

      const fromMailboxFound: MailboxConfiguration =
        mailboxesData.data?.find((mb) => mb.smtpAddress === senderAddress) ||
        mailboxesData.data?.find((mb) => mb.id === lastUsedMailboxId) ||
        mailboxesData.data?.find((mb) => mb.smtpAddress === user.email) ||
        mailboxesData.data?.[0];

      if (fromMailboxFound) {
        finalValues.from = fromMailboxFound.smtpAddress;
        finalValues.senderMailbox = fromMailboxFound;
      }

      finalValues.intendedSenderAddress ??= senderAddress;
      finalValues.project ??=
        activeTicketContext?.project ??
        additionalTemplateVariables?.project ??
        null;
      finalValues.projectId =
        finalValues.project?.id ??
        (additionalTemplateVariables?.projectId as number) ??
        0;

      setDraftValues(null);
      setValues(finalValues);
      setLoading(false);
      return;
    }

    if (mask.isCreateMode) {
      if (template.enabled && template.pending) {
        return;
      }

      if (previousEmail.pending) return;

      const previousEmailData = previousEmail.data[0];

      if (previousEmailData) {
        const collectiveAttrs: EMail = {
          kind: EMailKind.ENTWURF_HTML,
          bcc: '',
          topicMarkList: previousEmailData.topicMarkList || [],
          project: previousEmailData.project ?? null,
          projectId: previousEmailData.projectId || 0,
          businessPartner: previousEmailData.businessPartner || null,
          businessPartnerId: previousEmailData.businessPartnerId || 0,
          businessPartnerType:
            previousEmailData.businessPartnerType || SdObjType.LIEFERANT,
          contact: previousEmailData.contact || null,
          contactId: previousEmailData.contactId || 0,
          userId: user.benutzerCode,
        };

        const filterOwnEmailAddress = (value: string) =>
          value
            ?.split(';')
            .map((x) => x.toLowerCase().trim())
            .filter((y) => y.toLowerCase() !== user.email.toLowerCase())
            .filter((y) => Boolean(y))
            .join('; ') || '';

        const creteResponseEmail = (): EMail => {
          switch (actionMode) {
            case EMAILMODES.reply: {
              return {
                ...collectiveAttrs,
                to: previousEmailData.from,
                subject: prefixIfNeeded(
                  t('MASK.AW') + ': ',
                  previousEmailData?.subject
                ),
              };
            }

            case EMAILMODES.replyAll: {
              return {
                ...collectiveAttrs,
                to: [
                  ...new Set(
                    filterOwnEmailAddress(
                      (previousEmailData.from?.concat('; ') || '').concat(
                        previousEmailData.to || ''
                      )
                    ).split(';')
                  ),
                ].join(';'),
                subject: prefixIfNeeded(
                  t('MASK.AW') + ': ',
                  previousEmailData.subject
                ),
                cc: [
                  ...new Set(
                    filterOwnEmailAddress(previousEmailData.cc).split(';')
                  ),
                ].join(';'),
              };
            }

            case EMAILMODES.forward: {
              return {
                ...collectiveAttrs,
                subject: prefixIfNeeded(
                  t('MASK.WG') + ': ',
                  previousEmailData.subject
                ),
                attachmentList: previousEmailData.attachmentList,
              };
            }

            case EMAILMODES.sendAgain: {
              return {
                ...collectiveAttrs,
                to: previousEmailData.to,
                subject: previousEmailData.subject,
                cc: previousEmailData.cc,
                attachmentList: previousEmailData.attachmentList,
                priority: previousEmailData.priority,
              };
            }

            default:
              return null;
          }
        };

        const email: EMail = creteResponseEmail();

        if (email) {
          setDraftValues(email);
          return;
        }
      }

      const email = createEmail({ user, template, receivers });
      setDraftValues(email);
      return;
    }

    if (mask.isEditMode) {
      if (currentEmailData.pending) return;

      const email = currentEmailData.data[0];
      if (!email) {
        throw new Error('Email not found');
      }

      setDraftValues(email);
    }

    function assignContactsToValues(values: EMail): EmailMaskFormValue {
      const assignedContactsToValues = {
        ...values,
        toMailContactsInstance: parseMailContacts(
          values.toMailContacts,
          values.to,
          values.businessPartner?.data?.isPrivateCustomer
        ),
        ccMailContactsInstance: parseMailContacts(
          values.ccMailContacts,
          values.cc
        ),
        bccMailContactsInstance: parseMailContacts(
          values.bccMailContacts,
          values.bcc
        ),
      };
      const jsonEmails = assignedContactsToValues.toMailContactsInstance.map(
        (x) => x.mailAddress
      );
      const toEmails = (values.to ?? '').split(';');

      const allDiff = difference(jsonEmails, toEmails).filter((x) => x);
      if (allDiff.length) {
        assignedContactsToValues.to = [
          ...toEmails.filter((x) => x),
          ...allDiff,
        ].join(';');
        assignedContactsToValues.toMailContacts = JSON.stringify(
          assignedContactsToValues.toMailContactsInstance
        );
      }
      return assignedContactsToValues;

      function parseMailContacts(
        contactString: string,
        legacyString: string,
        isPrivatePartner?: boolean
      ): MailSearchContactsResultItem[] | null {
        if (!contactString) {
          if (isPrivatePartner) {
            return values.businessPartner?.data?.eMail
              ? [
                  {
                    id: `Email_${values.businessPartner?.data?.eMail}`,
                    mailAddress: values.businessPartner?.data?.eMail,
                    type: ContactType.BLANK_ADDRESS,
                  },
                ]
              : [];
          }
          if (!legacyString) return [];
          return legacyString
            .split(';')
            .filter(Boolean)
            .filter((x) => x.trim().length > 0)
            .map((email): MailSearchContactsResultItem => {
              const customerAp = customerApMailData?.data?.find(
                (x) =>
                  x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
              );

              const supplierAp = supplierApMailData?.data?.find(
                (x) =>
                  x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
              );

              const customer = customerMailData?.data?.find(
                (x) =>
                  x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
              );

              const supplier = supplierMailData?.data?.find(
                (x) =>
                  x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
              );

              const id = customerAp
                ? `KundeAp_${customerAp.id}`
                : supplierAp
                ? `LieferantAp_${supplierAp.id}`
                : customer
                ? `Kunde_${customer.id}`
                : supplier
                ? `Lieferant_${supplier.id}`
                : `Email_${email.toLowerCase().trim()}`;

              return {
                id: id,
                mailAddress: email,
                type: customerAp
                  ? ContactType.KUNDE_AP
                  : supplierAp
                  ? ContactType.LIEFERANT_AP
                  : ContactType.BLANK_ADDRESS,
              };
            });
        }

        const parsedMailContacts: MailSearchContactsResultItem[] =
          JSON.parse(contactString);

        const uniqMailContacts: MailSearchContactsResultItem[] = [];
        parsedMailContacts.forEach((mail) => {
          const mailFound = uniqMailContacts.find(
            (_mail) => _mail.mailAddress === mail.mailAddress
          );

          if (mailFound) return;

          uniqMailContacts.push(mail);
        });

        return uniqMailContacts;
      }
    }
  });

  const refresh = useEventCallback(() => {
    if (isLoading) return;
    setLoading(true);
  });

  useEffect(makeInitialValues);

  return { isLoading, values, refresh, previousEmail };
}

type ICreateEmailOptions = {
  template: AssignableEntityResult;
  user: Pick<IUser, 'email' | 'benutzerCode'>;
  bodyHtml?: string;
  subject?: string;
  receivers?: string;
};

function createEmail(params: ICreateEmailOptions): EMail {
  const { template, user, subject = '', receivers } = params;
  const isPrivateCustomer =
    template?.data?.businessPartner?.data?.isPrivateCustomer;
  const email: EMail = {
    userId: user.benutzerCode,
    kind: EMailKind.ENTWURF_HTML,
    topicMarkList: null,
    subject,
    attachmentList: [],
    businessPartner: null,
    businessPartnerType: SdObjType.LIEFERANT,
    businessPartnerId: 0,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
    to: '',
    toMailContacts: receivers && !isPrivateCustomer ? receivers : '',
    bcc: '',
    bccMailContacts: '',
    cc: '',
    ccMailContacts: '',
    bodyHtml: '',
  };

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const data: EMail = {
      ...email,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      businessPartnerType:
        template.data.businessPartner?.businessPartnerType ??
        template.data.businessPartnerType ??
        SdObjType.LIEFERANT,
      contact: template.data.contact,
      contactId: template.data.contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id ?? 0,
      to: template.data.contact?.eMail ?? null,
      topicMarkList: template.data.topicMarkList,
    };

    if (isPrivateCustomer) {
      return data;
    }

    const toMailContacts = calculateToMailContacts(template);

    const contactsParsed = toMailContacts ? JSON.parse(toMailContacts) : null;
    const receiversParsed = receivers ? JSON.parse(receivers) : null;

    const allContacts = [contactsParsed, receiversParsed]
      .filter(Boolean)
      .flat(1);

    if (allContacts.length > 0) {
      data.to = allContacts.map((c) => c.mailAddress).join(';');
      data.toMailContacts = JSON.stringify(allContacts);
    }

    return data;
  }

  return email;
}

function prefixIfNeeded(pfx: string, target?: string) {
  if (target && !target.trim().startsWith(pfx)) {
    return pfx + target;
  }
  return target;
}
