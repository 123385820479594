import { randomId } from './random-id';

const cache = new Map<string, number>();

function nextId(kind: string, name?: string): string {
  const previous = cache.get(kind) ?? 0;
  const id = previous + 1;
  cache.set(kind, id);

  const parts = [kind, name, id].filter(Boolean);
  return parts.join('-');
}

type Event = {
  type:
    | 'button'
    | 'submit'
    | 'mutate'
    | 'mutate-complete'
    | 'mutate-error'
    | 'link';
  id: string;
  t: number;
  [key: string]: string | number;
};

type Issue = {
  type: 'submit' | 'mutate' | 'link';
  id: string;
  [key: string]: string | number;
};

type TicketMutationDetails = {
  state: {
    session: string;
    button: string;
    submit: string;
    mutate: string;
    link: string;
  };
  eventsTotal: number;
  issuesTotal: number;
  events: Event[];
  issues: Issue[];
};

class TicketMutationGuard {
  private session = randomId();

  private button: string | null = null;
  private submit: string | null = null;
  private mutate: string | null = null;
  private link: string | null = null;

  private timestamp: number | null = null;

  private events: Event[] = [];
  private issues: Issue[] = [];

  reset() {
    this.button = null;
    this.submit = null;
    this.mutate = null;
    this.link = null;

    this.timestamp = null;

    this.events = [];
    this.issues = [];
  }

  resetIfStale() {
    if (this.timestamp !== null && performance.now() - this.timestamp > 2000) {
      this.reset();
    }
  }

  log(
    init: Pick<Event, 'type' | 'id'>,
    extras?: Record<string, string | number>
  ) {
    const { type, id } = init;
    const t = performance.now();

    if (
      type !== 'button' &&
      type !== 'mutate-complete' &&
      type !== 'mutate-error' &&
      this[type] !== null
    ) {
      const issue: Issue = { type, id, conflict: this[type] };
      this.issues.push(issue);
    }

    const event: Event = { type, id, t, ...extras };
    this.events.push(event);

    this[init.type] = event.id;
    this.timestamp = event.t;
  }

  markButton(name: string) {
    this.resetIfStale();

    const id = nextId('Button', name);
    this.log({ type: 'button', id });
  }

  markSubmit() {
    const id = nextId('Submit');
    this.log({ type: 'submit', id }, { button: this.button });
  }

  markMutate() {
    const id = nextId('Mutate');
    this.log({ type: 'mutate', id }, { submit: this.submit });
    return id;
  }

  markMutateComplete(id: string) {
    this.log({ type: 'mutate-complete', id });
  }

  markMutateError(id: string) {
    this.log({ type: 'mutate-error', id });
  }

  markLink() {
    const id = nextId('Link');
    this.log({ type: 'link', id }, { mutate: this.mutate });
  }

  shouldBlock() {
    return this.issues.length > 0;
  }

  collect() {
    return {
      state: {
        session: this.session,
        button: this.button,
        submit: this.submit,
        mutate: this.mutate,
        link: this.link,
      },
      eventsTotal: this.events.length,
      issuesTotal: this.issues.length,
      events: this.events,
      issues: this.issues,
    };
  }

  createError() {
    const error = new TicketMutationBlockedError(this);
    Error.captureStackTrace?.(error, this.createError);
    return error;
  }
}

export const __TICKET_MUTATION_GUARD = new TicketMutationGuard();

export function isTicketMutationGuardError(
  error: unknown
): error is TicketMutationBlockedError {
  return error instanceof TicketMutationBlockedError;
}

class TicketMutationBlockedError extends Error {
  details: TicketMutationDetails;

  constructor(init: TicketMutationGuard) {
    super();

    const details = init.collect();

    this.name = 'TicketMutationBlockedError';
    this.message = `The ticket mutation has been blocked due to unexpected behavior.`;
    this.details = details;
  }
}
